/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { makeHandle as e } from "../../core/handleUtils.js";
import { widgetTestDataSymbol as t, widgetSymbol as r } from "./symbols.js";
const o = [],
  n = {},
  i = new WeakMap();
function d(e, t) {
  let r = t.children;
  if (r?.length) for (let o = 0; o < r.length; ++o) r[o] = d(e, r[o]);else r = o;
  const i = t.vnodeSelector;
  if (f(i)) {
    const o = t.properties || n,
      d = o.key || i,
      f = {
        key: d,
        afterCreate: c,
        afterUpdate: s,
        afterRemoved: a,
        parentWidget: e,
        widgetConstructor: i,
        widgetProperties: {
          ...o,
          key: d,
          children: r
        }
      };
    return {
      vnodeSelector: i.vnodeSelector,
      properties: f,
      children: void 0,
      text: void 0,
      domNode: null
    };
  }
  return t;
}
function c(r, o, n, {
  parentWidget: d,
  widgetConstructor: c,
  widgetProperties: s
}) {
  const f = new c(s);
  f.container = r, i.set(r, f), f.afterCreate?.(f, r), d.addHandles(e(() => a(r))), queueMicrotask(() => {
    f[t].projector.renderNow();
  });
}
function s(e, t, r, {
  widgetProperties: o
}) {
  const n = i.get(e);
  n && (n.set(o), n.afterUpdate?.(n, e));
}
function a(e) {
  const t = i.get(e);
  t && (t.afterRemoved?.(t, e), t.destroy(), i.delete(e));
}
function f(e) {
  return "function" == typeof e && e[r];
}
export { f as isWidgetConstructor, d as processWidgets };